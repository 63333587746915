import React, { Component } from 'react';
import Amplify, { API } from 'aws-amplify';
import './App.css';

import { Alert } from 'react-bootstrap';

const API_KEY = 'BptKpRCWbK1YuoXiU36zN44F15UxUGUt58I3JFBv';

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "SheetAssistant-API",
                endpoint: "https://7li8vx1d67.execute-api.eu-west-1.amazonaws.com/default/SheetAssistant-API"
            }
        ]
    }
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSuccess: false,
            showError: false,
            userId: "",
            uuid: "",
            keyword: "",
            spreadsheetId: "",
            range: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickNext = this.handleClickNext.bind(this);
        this.handleDismissSuccess = this.handleDismissSuccess.bind(this);
        this.handleShowSuccess = this.handleShowSuccess.bind(this);
        this.handleDismissError = this.handleDismissError.bind(this);
        this.handleShowError = this.handleShowError.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleClick(event) {
        event.preventDefault();
        this.updateInquiry();
    }

    handleClickNext(event) {
        event.preventDefault();
        let value = this.refs.lookupId.value;
        this.getInquiry(value);
    }

    handleDismissSuccess() {
        this.setState({ showSuccess: false });
    }

    handleShowSuccess() {
        this.handleDismissError();
        this.setState({ showSuccess: true });
    }

    handleDismissError() {
        this.setState({ showError: false });
    }

    handleShowError() {
        this.handleDismissSuccess();
        this.setState({ showError: true });
    }

    componentDidMount() {}

    getInquiry(lookupId) {
        let myInit = {
            headers: {
                "x-api-key":API_KEY
            },
        }

        API.get(
            'SheetAssistant-API',
            '/inquiry/' + lookupId,
            myInit
        ).then(response => {
            this.handleDismissError();
            this.setState(response);
        }).catch(error => {
            this.handleShowError();
            console.log(error);
        });
    }

    updateInquiry() {
        let myInit = {
            body: {
                spreadsheetId: this.state.spreadsheetId,
                range: this.state.range
            },
            headers: {
                "x-api-key":API_KEY
            },
        }

        API.patch(
            'SheetAssistant-API',
            '/inquiry/' + this.state.uuid + '?userId=' + this.state.userId,
            myInit
        ).then(response => {
            this.handleShowSuccess();
        }).catch(error => {
            this.handleShowError();
             console.log(error);
        });
    }

    getLookupView() {
        return (
            <div className="App">
                <h1>Sheet Assistant: Abfrage einrichten</h1>

                <div className="container">
                    {this.state.showError &&
                        <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                          <h4>Keine Abfrage mit der Nummer gefunden</h4>
                          <p>
                            Es konnte keine Abfrage mit der Nummer gefunden werden. Bitte überprüfe die Nummer in deiner Alexa App unter "Aktivität".
                          </p>
                        </Alert>
                    }

                    <form>
                      <div className="form-group row">
                        <label htmlFor="lookupId" className="col-sm-3 col-form-label">Abfragenummer</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="lookupId" ref="lookupId" name="lookupId" aria-describedby="lookupIdHelp" />
                            <small id="lookupIdHelp" className="form-text text-muted">Die 6-stellige Nummer, die Alexa dir genannt hat.</small>
                        </div>
                      </div>

                      <button type="submit" className="btn btn-success" onClick={this.handleClickNext}>Weiter</button>
                    </form>
                  </div>
            </div>
        );
    }

    getInquiryView() {
        return (
            <div className="App">
                <h1>Sheet Assistant: Abfrage einrichten</h1>

                <div className="container">
                    {this.state.showSuccess &&
                        <Alert bsStyle="success" onDismiss={this.handleDismissSuccess}>
                          <h4>Abfrage erfolgreich eingerichtet</h4>
                          <p>
                            Die Abfrage wurde erfolgreich eingerichtet und kann jetzt genutzt werden. Sage dazu "Alexa, sage Sheet Assistant {this.state.keyword} abfragen".
                          </p>
                        </Alert>
                    }

                    {this.state.showError &&
                        <Alert bsStyle="danger" onDismiss={this.handleDismissError}>
                          <h4>Abfrage fehlgeschlagen</h4>
                          <p>
                            Die Abfrage konnte nicht gespeichert werden. Bitte prüfe ob alle Felder korrekt ausgefüllt sind.
                          </p>
                        </Alert>
                    }

                    <form>
                      <div className="form-group row">
                        <label htmlFor="keyword" className="col-sm-3 col-form-label">Keyword</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="keyword" name="keyword" aria-describedby="keywordHelp" value={this.state.keyword} onChange={this.handleChange} disabled />
                            <small id="keywordHelp" className="form-text text-muted">The keyword for activation.</small>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="spreadsheetId" className="col-sm-3 col-form-label">Spreadsheet ID</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="spreadsheetId" name="spreadsheetId" aria-describedby="spreadsheetIdHelp" value={this.state.spreadsheetId} onChange={this.handleChange} />
                            <small id="spreadsheetIdHelp" className="form-text text-muted">The ID of the google spreadsheet to query.</small>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="range" className="col-sm-3 col-form-label">Range</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="range" name="range" aria-describedby="rangeHelp" value={this.state.range} onChange={this.handleChange} />
                            <small id="rangeHelp" className="form-text text-muted">The spreadsheet range to query, e.g. "Dashboard!A3:B3".</small>
                        </div>
                      </div>

                      <button type="submit" className="btn btn-success" onClick={this.handleClick}>Abfage einrichten</button>
                    </form>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.keyword === "") {
            return this.getLookupView();
        } else {
            return this.getInquiryView();
        }
    }
}

export default App;
